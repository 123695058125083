import { MapPinIcon } from '@heroicons/react/24/solid'
import cn from 'classnames'

type Props = {
  location?: string
  country?: string
  city?: string
  state?: string
  variant?: 'profile-page' | 'profile-suggestion-card'
  hideIfEmpty?: boolean
}

const UserAddress = (props: Props) => {
  const { location, country, city, state, variant = 'profile-page', hideIfEmpty } = props
  const hasAddress = location || country // country is required to select state and city
  if (!hasAddress && hideIfEmpty) return null
  return (
    <div
      className={cn(
        'inline-flex items-center tracking-wide',
        variant === 'profile-page' && 'text-[10px] font-medium text-kokushoku-black/50 lg:text-[14px]',
        variant === 'profile-suggestion-card' && 'text-[10px] font-medium !text-black/60 2xl:text-[12px]',
      )}
    >
      <MapPinIcon className="h-[1.25em] w-[1.25em]" />
      &nbsp;
      {/* show by location field if it's provided */}
      {location && <span>{location}</span>}
      {/* show by locations props */}
      {!location && country && [city, state, country].filter(Boolean).join(', ')}
    </div>
  )
}

export default UserAddress
