import { MeasurementId } from 'configs/enums'
import { ShippingInformationFormData } from 'types/V3/Artwork'
import { ArtworkShippingInformation } from 'views/creator-artwork-form/types'

export const getShippingInformation = <T extends ShippingInformationFormData>(data: T, isForSale: boolean) => {
  const isShippingDimensionsSet = data.shippingWidth || data.shippingHeight || data.shippingDepth
  const shippingInformation: null | ArtworkShippingInformation = isForSale
    ? {
        type: data.shippingOption || 'box',
        dimensions: isShippingDimensionsSet
          ? {
              width: data.shippingWidth,
              height: data.shippingHeight,
              depth: data.shippingDepth,
              unit: data.shippingMeasument === MeasurementId.IMPERIAL ? 'in' : 'cm',
            }
          : null,
        hasDecorativeFrame: data.hasDecorativeFrame,
      }
    : null

  return shippingInformation
}

export const getUTMQueries = (params: {
  userId?: number
  utmMedium?: string
  utmCampaign: string
  utmContent: number | string
}) => {
  const { userId, utmMedium = 'referral', utmCampaign, utmContent } = params
  return `?utm_source=${
    userId ?? 'guest'
  }&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_content=${utmContent} `
}
