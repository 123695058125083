type MakeProfileTitleInput = {
  fullName: string
  subtitle: string
}

export const makeProfilePageTitle = (input: MakeProfileTitleInput) => {
  const { fullName, subtitle } = input
  const pageTitle = [fullName, subtitle, 'COHART'].filter((v) => !!v).join(' | ')
  return pageTitle
}
