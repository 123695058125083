import { useCallback } from 'react'
import cn from 'classnames'
import FollowIcon from 'shared/icons/FollowIcon'
import FollowingIcon from 'shared/icons/FollowingIcon'
import ShareIcon from 'shared/icons/ShareIcon'
import { useRouter } from 'next/router'
import { nativeShare } from 'utils/common'
import { WEB_BASE_URL } from 'configs'
import { notifySuccess } from 'utils/toast'
import PencilIcon from 'shared/icons/PencilIcon'
import useFollowingUser from 'shared/hooks/useFollowingUser'
import useAuth from 'hooks/authentication/useAuth'
import { getUTMQueries } from 'shared/utils'

type FollowAndShareSectionProps = {
  userId: number
  username: string
  isFollowing: boolean
  isCurrentUser: boolean
  fullName: string
}

const FollowAndShareSection = (props: FollowAndShareSectionProps) => {
  const { userId, username, isCurrentUser, isFollowing: isFollowingInitial, fullName } = props
  const router = useRouter()

  const { authUser } = useAuth()

  const { isFollowing, toggleFollowing, isToggleFollowing } = useFollowingUser({
    userId,
    isFollowingInitial,
    fullName,
  })

  const editProfile = useCallback(() => {
    router.push('/u/edit')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shareProfile = useCallback(async () => {
    const linkToUserProfile = `https://${WEB_BASE_URL}/u/${username}${getUTMQueries({
      userId: authUser?.id,
      utmCampaign: 'profile-link-share',
      utmContent: userId,
    })}`
    const isNativeSharingSupported = await nativeShare({ url: linkToUserProfile }, linkToUserProfile)
    !isNativeSharingSupported && notifySuccess('Share link copied to the clipboard') // show toast if native sharing is not supported
  }, [authUser?.id, userId, username])

  return (
    <div className="inline-flex h-[32px] w-full items-stretch gap-2 lg:h-[50px] lg:justify-center">
      {/* follow/unfollow button */}
      {!isCurrentUser && (
        <button
          className={cn(
            'inline-flex items-center justify-center px-3 hover:shadow-md lg:px-6',
            'w-fit rounded-full border border-black/10 lg:min-w-[180px]',
            isFollowing ? 'bg-white text-black' : 'bg-primary text-white',
            'disabled:opacity-40 disabled:hover:shadow-none',
          )}
          onClick={toggleFollowing}
          disabled={isToggleFollowing}
        >
          {!isFollowing && <FollowIcon className="size-[22px] lg:size-[36px]" />}
          {isFollowing && <FollowingIcon className="size-[14px] origin-center lg:size-[21px]" />}
          <span className="ml-1 text-[10px] font-medium lg:text-[16px]">{isFollowing ? 'Following' : 'Follow'}</span>
        </button>
      )}

      {/* edit button */}
      {isCurrentUser && (
        <button
          className={cn(
            'inline-flex items-center justify-center px-3 hover:shadow-md lg:px-6',
            'w-fit rounded-full border border-black/10 lg:min-w-[180px]',
            'bg-white text-black',
          )}
          onClick={editProfile}
        >
          <PencilIcon className="size-[13px] lg:size-[21px]" />
          <span className="ml-1 text-[10px] font-medium lg:ml-2 lg:text-[16px]">Edit Profile</span>
        </button>
      )}

      {/* share button */}
      <button
        className={cn(
          'inline-flex items-center justify-center px-3 hover:shadow-md lg:px-6',
          'w-fit rounded-full border border-black/10 lg:min-w-[180px]',
          'bg-white text-black',
        )}
        onClick={shareProfile}
      >
        <ShareIcon className="size-[13px] lg:size-[21px]" />
        <span className="ml-1 text-[10px] font-medium lg:ml-2 lg:text-[16px]">Share Profile</span>
      </button>
    </div>
  )
}

export default FollowAndShareSection
