import cn from 'classnames'
import AvatarGroup from 'shared/components/avatar/AvatarGroup'
import RoundedAvatarImage from 'shared/components/avatar/RoundedAvatarImage'
import { memo } from 'react'
import { UserBase } from 'types/V3/User'

type Props = {
  alsoFollowed: Array<UserBase>
}

const AlsoFollowedBySection = (props: Props) => {
  const { alsoFollowed } = props

  if (!alsoFollowed || alsoFollowed.length === 0) return null
  const displayName = alsoFollowed.map(({ fullName }) => fullName).join(', ')
  return (
    <div className="flex items-center gap-[0.5ch] lg:justify-center">
      <div className="inline-flex items-center">
        <AvatarGroup>
          {alsoFollowed?.slice(0, 3).map(({ id, fullName, coverImage }) => (
            <RoundedAvatarImage
              key={id}
              className={cn('h-[18px] w-[18px] border-black/10 shadow-lg')}
              alt={fullName}
              src={coverImage}
            />
          ))}
        </AvatarGroup>
      </div>
      <p className="line-clamp-1 text-[10px] text-kokushoku-black/50 lg:text-[12px]">
        Also followed by&nbsp;
        <span className="font-semibold">{displayName}</span>
      </p>
    </div>
  )
}

export default memo(AlsoFollowedBySection)
