const FollowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4888 15.3015C17.6715 15.3015 19.4409 13.5321 19.4409 11.3494C19.4409 9.16675 17.6715 7.39734 15.4888 7.39734C13.3061 7.39734 11.5367 9.16675 11.5367 11.3494C11.5367 13.5321 13.3061 15.3015 15.4888 15.3015ZM18.1853 15.7657C19.671 14.8566 20.6624 13.2188 20.6624 11.3494C20.6624 8.4921 18.3461 6.17578 15.4888 6.17578C12.6314 6.17578 10.3151 8.4921 10.3151 11.3494C10.3151 13.2188 11.3065 14.8565 12.7922 15.7657C11.8177 16.0848 10.9191 16.5721 10.1314 17.1932C9.86651 17.402 9.82109 17.7861 10.0299 18.051C10.2388 18.3158 10.6228 18.3613 10.8877 18.1524C12.1537 17.1543 13.7507 16.559 15.4885 16.559C19.5959 16.559 22.9256 19.8887 22.9256 23.9961C22.9256 24.3335 23.199 24.6069 23.5364 24.6069C23.8737 24.6069 24.1471 24.3335 24.1471 23.9961C24.1471 20.1557 21.6469 16.8992 18.1853 15.7657ZM8.68439 19.4334C8.68439 19.096 8.41093 18.8226 8.07361 18.8226C7.73629 18.8226 7.46283 19.096 7.46283 19.4334V21.6248H5.17718C4.83986 21.6248 4.56641 21.8982 4.56641 22.2356C4.56641 22.5729 4.83986 22.8463 5.17718 22.8463H7.46283V25.11C7.46283 25.4473 7.73629 25.7208 8.07361 25.7208C8.41093 25.7208 8.68439 25.4473 8.68439 25.11V22.8463H10.8538C11.1912 22.8463 11.4646 22.5729 11.4646 22.2356C11.4646 21.8982 11.1912 21.6248 10.8538 21.6248H8.68439V19.4334Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FollowIcon
