import cn from 'classnames'
import CustomImage, { CustomImageProps } from 'shared/components/CustomImage'

type RoundedAvatarImageProps = {
  src?: string | null
  className?: string
  alt?: string
  zIndex?: number
  sizes?: CustomImageProps['sizes']
  quality?: CustomImageProps['quality']
  onClick?: () => void
}

const RoundedAvatarImage = (props: RoundedAvatarImageProps) => {
  const { className, src, alt, zIndex, sizes = '256px', quality = 80, onClick } = props
  const displayPhoto = src || '/icons/default-ava.svg'

  return (
    <span
      className={cn(
        'relative box-content inline-block overflow-hidden rounded-full outline outline-1 outline-black/10',
        className,
      )}
    >
      <CustomImage
        src={displayPhoto}
        blurDataURL="/icons/default-ava.svg"
        placeholder="blur"
        alt={alt || 'user avatar'}
        fill
        className="rounded-full object-cover object-center"
        style={{ zIndex }}
        fallbackSrc="/images/default.png"
        sizes={sizes}
        quality={quality}
        onClick={onClick}
      />
    </span>
  )
}

export default RoundedAvatarImage
