import cn from 'classnames'

type Props = {
  children: React.ReactNode
  className?: string
  onClick?: () => void
}

const AvatarGroup = (props: Props) => {
  const { children, className, onClick } = props

  return (
    <div
      onClick={onClick}
      className={cn('inline-flex items-center space-x-[-8px]', className, !!onClick && 'cursor-pointer')}
    >
      {children}
    </div>
  )
}

export default AvatarGroup
