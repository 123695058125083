import { NewBackEndApiResponseData } from 'types/ApiResponseData'
import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'
import { withLogger } from 'utils/Logger'

const followUser = async (userId: number) => {
  const response = await withApiErrorHandled(apiClient.post)(`/v2/users/${userId}/follow`, {})
  const { data } = response.data as NewBackEndApiResponseData<unknown>
  return data
}

export default withLogger(followUser, {
  action: 'follow_user',
  customErrorText: 'Cannot follow user',
})
