const FollowingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44141 7.97059C10.2282 7.97059 11.6767 6.5221 11.6767 4.73529C11.6767 2.94849 10.2282 1.5 8.44141 1.5C6.65461 1.5 5.20612 2.94849 5.20612 4.73529C5.20612 6.5221 6.65461 7.97059 8.44141 7.97059ZM12.6767 4.73529C12.6767 6.2656 11.8651 7.60634 10.6488 8.35055C13.4826 9.27849 15.5294 11.9443 15.5294 15.0882C15.5294 15.3644 15.3055 15.5882 15.0294 15.5882C14.7533 15.5882 14.5294 15.3644 14.5294 15.0882C14.5294 11.7258 11.8036 9 8.44116 9C7.01858 9 5.71121 9.48728 4.67488 10.3044C4.45803 10.4754 4.14364 10.4382 3.97267 10.2213C3.80169 10.0045 3.83888 9.69011 4.05572 9.51913C4.70058 9.01068 5.43613 8.61176 6.23395 8.35053C5.01771 7.60631 4.20612 6.26558 4.20612 4.73529C4.20612 2.39621 6.10233 0.5 8.44141 0.5C10.7805 0.5 12.6767 2.39621 12.6767 4.73529ZM6.40001 11.8C6.5657 11.5791 6.52093 11.2657 6.30001 11.1C6.0791 10.9343 5.7657 10.9791 5.60001 11.2L2.97936 14.6942L1.38412 12.7799C1.20734 12.5678 0.892059 12.5391 0.67992 12.7159C0.467782 12.8927 0.43912 13.208 0.615902 13.4201L2.6159 15.8201C2.71377 15.9375 2.85999 16.0038 3.01282 15.9998C3.16565 15.9959 3.30829 15.9223 3.40001 15.8L6.40001 11.8Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FollowingIcon
