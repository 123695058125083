import dynamic from 'next/dynamic'
import { memo } from 'react'
import StatisticItem from './StatisticItem'

const CreatorArtworkStat = dynamic(
  () => import('views/profile-page/components/FollowInfoSection/CreatorArtworkStat'), //
  { ssr: false },
)

const CollectorCollectionStat = dynamic(
  () => import('views/profile-page/components/FollowInfoSection/CollectorCollectionStat'),
  { ssr: false },
)

const CohartAdvisorManagedArtistStat = dynamic(
  () => import('views/profile-page/components/FollowInfoSection/CohartAdvisorManagedArtistStat'),
  { ssr: false },
)

type FollowInfoSectionProps = {
  userId: number
  username: string
  followerCount: number
  followingCount: number
  // roles
  isUserCollector: boolean
  isUserCreator: boolean
  isUserCA: boolean
  isUserIA: boolean
  isUserHenry: boolean
}

const FollowInfoSection = (props: FollowInfoSectionProps) => {
  const {
    userId,
    username,
    followerCount,
    followingCount,

    isUserCollector,
    isUserCreator,
    isUserCA,
  } = props

  return (
    <div className="inline-flex w-fit items-center gap-2">
      {isUserCreator && <CreatorArtworkStat userId={userId} />}
      {isUserCollector && <CollectorCollectionStat userId={userId} />}
      {isUserCA && <CohartAdvisorManagedArtistStat userId={userId} />}

      <StatisticItem
        href={`/u/${username}/followers`}
        text="followers"
        value={followerCount}
        withBorder={true}
        isLinkActive={true}
      />

      <StatisticItem
        href={`/u/${username}/following`}
        text="following"
        value={followingCount}
        withBorder={false}
        isLinkActive={true}
      />
    </div>
  )
}

export default memo(FollowInfoSection)
