import cn from 'classnames'
import { ComponentType, useState } from 'react'
import SideBar from 'shared/components/display/SideBar'
import useAppLayout from 'shared/hooks/useAppLayout'

const withDesktopSidebar = <P extends Record<string, unknown>>(Component: ComponentType<P>) => {
  const PageWithDesktopSidebar = (props: P) => {
    const {
      appLayout: { fullHeightSidebar },
    } = useAppLayout()
    const [isSidebarVisible, setSidebarVisibility] = useState(false)

    return (
      <>
        {/* desktop sidebar */}
        <div
          className={cn(
            'fixed left-0 z-50 w-[240px] transition-all',
            'shadow-lg outline outline-1 outline-black/10',
            'hidden',
            fullHeightSidebar ? 'top-0 h-screen' : 'top-20 h-[calc(100vh-80px)]',
            isSidebarVisible && 'lg:block',
          )}
        >
          <div className="h-full w-full bg-white">
            <SideBar onDesktopSidebarReady={setSidebarVisibility} isDesktopSidebar />
          </div>
        </div>

        {/* content */}
        <div className={cn('w-full pl-0 transition-all', isSidebarVisible && 'lg:pl-[240px]')}>
          <Component {...props} isSidebarVisible={isSidebarVisible} />
        </div>
      </>
    )
  }

  PageWithDesktopSidebar.displayName = `WithDesktopSidebar(${Component.displayName || ''})`
  return PageWithDesktopSidebar
}

export default withDesktopSidebar
