import { RectangleStackIcon } from '@heroicons/react/24/solid'
import cn from 'classnames'
import { TabBarItemRenderer } from './types'
import NameWithVerificationBadge from 'shared/components/display/NameWithVerificationBadge'

type ProfileTabBarItemType = {
  href: string
  icon?: React.ReactNode
  content: string | TabBarItemRenderer
}

const OverviewItem: ProfileTabBarItemType = {
  href: '/u/[username]', // the tabbar will replace [username] with the actual username
  icon: <RectangleStackIcon className={cn('h-4 w-4 lg:h-5 lg:w-5')} aria-hidden="true" />,
  content: (params) => {
    const { fullname, isVerified, isStickyOnTop } = params
    if (!isStickyOnTop) return 'Overview'
    return <NameWithVerificationBadge fullName={fullname} isVerified={isVerified} maxNameLength={20} />
  },
}

export const CreatorTabBar: Array<ProfileTabBarItemType> = [
  OverviewItem,
  {
    content: 'Artworks',
    href: '/u/[username]/artworks',
  },
  {
    content: 'Moments',
    href: '/u/[username]/moments',
  },
  {
    content: 'Moodboards',
    href: '/u/[username]/moodboards',
  },
]

export const CollectorTabBar: Array<ProfileTabBarItemType> = [
  OverviewItem,
  {
    content: 'Collections',
    href: '/u/[username]/collections',
  },
  {
    content: 'Moments',
    href: '/u/[username]/moments',
  },
  {
    content: 'Moodboards',
    href: '/u/[username]/moodboards',
  },
]

export const IndependentAdvisorTabBar: Array<ProfileTabBarItemType> = [
  OverviewItem,
  {
    content: 'Moments',
    href: '/u/[username]/moments',
  },
  {
    content: 'Moodboards',
    href: '/u/[username]/moodboards',
  },
]

export const CohartAdvisorTabBar: Array<ProfileTabBarItemType> = [
  OverviewItem,
  {
    content: 'Artists',
    href: '/u/[username]/artists',
  },
  {
    content: 'Moments',
    href: '/u/[username]/moments',
  },
  {
    content: 'Moodboards',
    href: '/u/[username]/moodboards',
  },
]

export const HenryTabBar: Array<ProfileTabBarItemType> = [
  OverviewItem,
  {
    content: 'Moments',
    href: '/u/[username]/moments',
  },
  {
    content: 'Moodboards',
    href: '/u/[username]/moodboards',
  },
]
