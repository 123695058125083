import { useCallback, useEffect, useState } from 'react'
import unfollowUser from 'services/me/unfollowUser'
import followUser from 'services/me/followUser'
import useSWR from 'swr'
import { notifyError } from 'utils/toast'
import useAuth from 'hooks/authentication/useAuth'

type UseFollowingUserParams = {
  userId: number
  isFollowingInitial: boolean
  fullName?: string
}

const useFollowingUser = (params: UseFollowingUserParams) => {
  const { userId, isFollowingInitial, fullName } = params
  const [isToggleFollowing, setIsToggleFollowing] = useState(false)

  const {
    showAuthModal,
    setAuthMode,
    isLoggedIn,
    authUser,
    isFetching: isFetchingAuthInfo,
    setMarketingMessage,
    setCustomizedSignUpTitle,
    handleSuccess,
    closeAuthModal,
  } = useAuth()
  const isCurrentUser = authUser?.id === userId
  const swrKey = `me/following/${userId}` // a hack to sync the following status with other components use this hook
  const { data: isFollowing, mutate } = useSWR(swrKey, () => isFollowingInitial, { revalidateOnFocus: false })

  useEffect(() => {
    mutate(isFollowingInitial, { revalidate: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFollowingInitial])

  const updateFollowingStatus = useCallback(async () => {
    await mutate(
      async (isFollowing) => {
        try {
          const handler = isFollowing ? unfollowUser : followUser
          setIsToggleFollowing(true)
          await handler(userId)
          return !isFollowing
        } catch (error) {
          const message = typeof error === 'string' ? error : 'Something went wrong. Please try again later.'
          notifyError(message)
          throw error
        } finally {
          setIsToggleFollowing(false)
        }
      },
      { optimisticData: (v) => !v, revalidate: false, rollbackOnError: true },
    )
  }, [mutate, userId])

  const toggleFollowing = useCallback(async () => {
    // if user is not logged in, show the auth modal so they can sign in
    if (!isLoggedIn) {
      setAuthMode('signup')
      showAuthModal()
      handleSuccess(async () => {
        // if user is logged in, toggle the following status
        closeAuthModal()
        await updateFollowingStatus()
      })
      setMarketingMessage(`Sign up to follow ${fullName || 'the user'} so you never miss their update`)
      setCustomizedSignUpTitle('Join Cohart')
      return
    }

    // prevent the user from follow/unfollow himself
    if (isCurrentUser) {
      console.error('User cannot follow/unfollow himself')
    }

    // if user is logged in, toggle the following status
    await updateFollowingStatus()
  }, [
    isLoggedIn,
    isCurrentUser,
    updateFollowingStatus,
    setAuthMode,
    showAuthModal,
    handleSuccess,
    setMarketingMessage,
    fullName,
    setCustomizedSignUpTitle,
    closeAuthModal,
  ])

  return {
    isFollowing: Boolean(isFollowing),
    toggleFollowing,
    isToggleFollowing,
    isCurrentUser,
    isLoggedIn,
    isFetchingAuthInfo, // use this to hide the follow button when the auth info is fetching
  }
}

export default useFollowingUser
