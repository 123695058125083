import Link from 'next/link'
import { abbreviateNumber } from 'js-abbreviation-number'

type StatisticItemProps = {
  value?: number
  text: string
  withBorder: boolean
  onClick?: () => void
  href?: string
  isLinkActive?: boolean
}

const formatNumber = (number: number) => {
  return abbreviateNumber(number, 1)
}

const StatisticItem = (props: StatisticItemProps) => {
  const { value, text, withBorder, onClick, href, isLinkActive } = props

  if (value === undefined) return null // is fetching

  const content = (
    <a className="flex flex-col items-center px-1 lg:items-start lg:px-4" onClick={onClick}>
      <span className="text-[12px] font-semibold tracking-wide text-kokushoku-black lg:text-[20px] lg:tracking-tight">
        {formatNumber(value)}
      </span>
      <span className="text-[10px] capitalize tracking-wide text-[#B5B6C4] lg:text-[14px] lg:normal-case lg:tracking-tight">
        {text}
      </span>
    </a>
  )

  return (
    <>
      {href && isLinkActive && (
        <Link passHref href={href} legacyBehavior>
          {content}
        </Link>
      )}

      {href && !isLinkActive && content}

      {!href && content}
      {/* separator */}
      {withBorder && <div className="h-2/3 w-0 border-r-2 border-[#8B8B8B]/30" />}
    </>
  )
}

export default StatisticItem
